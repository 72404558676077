@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/vars.scss';

@import '@cpv/components/CPVScanner/cpv-scanner';
@import '@cpv/components/CPVQrDataTable/cpv-qr-data-table';
@import '@cpv/content/CPVLandingPage/cpv-landing-page';

.app__content {
  padding: 0;
}
