.cpv-landing-page__grid {
  max-width: 600px;
  padding: 0 $spacing-06;
}

.max-width-514 {
  max-width: 514px;
  margin-left: auto;
  margin-right: auto;

  @media (max-height: 650px) {
    max-width: 350px;
  }
}

.cpv-landing-page__banner {
  @media (max-height: 650px) {
    padding: $spacing-06 0 $spacing-04 0;
  }
  padding: $spacing-06 0 $spacing-04 0;
}

.cpv-landing-page__heading {
  padding-bottom: $spacing-03;
  @include carbon--type-style('productive-heading-06');
  text-align: center;
  strong {
    font-size: 30px;
  }
  font-size: 30px;
  @media (max-width: 675px) {
    strong {
      font-size: 4vw;
    }
    font-size: 4vw;
  }
  @media (max-height: 650px) {
    strong {
      font-size: 3vw;
    }
    font-size: 3vw;
  }
}

.cpv-landing-page__subheading {
  @include carbon--type-style('productive-heading-03');
}

.cpv-landing-page__scanner {
  @media (max-height: 650px) {
    padding: $spacing-04 0 $spacing-06 0;
  }
  padding: $spacing-03 0;
}

.retangle {
  width: 100%;
}

.cpv-landing-page__disclaimer {
  color: $text-05;

  p {
    margin-bottom: $spacing-03;
  }

  a, a:hover, a:active {
    text-decoration: none;
    color: $link-01;
  }

  .email {
    @include carbon--type-style('code-02');
  }
}

.cpv-landing-page__footer {
  margin-top: $spacing-06;
  padding: $spacing-03;
  background-color: $ui-01;
  color: $disabled-02;
  text-align: center;
}

.d-flex {
  display: flex;
  flex-wrap: wrap;
}

.background {
  background: #dfd0ba;
}

.p-2 {
  padding:.5rem;
}

.logo-width {
  @media (max-width: 675px) {
    width: 85% !important;
    height: unset;
    max-height: unset;
    margin-left: auto;
  }
  height: auto;
  width: auto;
  max-height: 100px;
  margin-right: auto;
  margin-left: 10px;
}
