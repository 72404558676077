.cpv-scanner__grid {
  padding: 0 $spacing-05;
}

.cpv-scanner__status {
  text-align: center;
  font-size: 15px;

  &.red { color: $support-01; }
  &.amber { color: $support-03; }
  &.green { color: $support-02; }
  &.icon-size svg {
    height: 100px;
    width: 100px;
  }
}
